<template>
<div class="card">
  <div class="common-title">{{$t('车辆信息')}}</div>
  <div class="card-info">
    <div class="sub-title">{{ carInfo?.carOrderMaterial?.seriesName }} {{ carInfo?.carOrderMaterial?.modelName }}</div>
    <div class="item">
      <span class="label">{{$t('车身颜色：')}}</span>
      <span class="value">{{ carInfo?.carOrderMaterial?.colourName }}</span>
    </div>
    <div class="item">
      <span class="label">{{$t('内饰颜色：')}}</span>
      <span class="value">{{ carInfo?.carOrderMaterial?.interiorName }}</span>
    </div>
    <div class="item">
      <span class="label">VIN：</span>
      <span class="value">{{ carInfo?.vin }}</span>
    </div>
  </div>
</div>
</template>
<script>
export default {
  props: {
    carInfo:{
      type:Object,
      default:()=>({})
    }
  }
}
</script>
<style lang="less" scoped>
.card{
  padding:  0 12px 4px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  .sub-title{
    padding: 12px 0;
  }
}
.item{
  margin-bottom: 8px;
  .label{
    height: 16px;
    font-size: 14px;
    font-family: HYQiHei, HYQiHei;
    font-weight: normal;
    color: rgba(13,23,26,0.45);
    line-height: 16px;
  }
  .value{
    height: 16px;
    font-size: 14px;
    font-family: HYQiHei, HYQiHei;
    font-weight: normal;
    color: #0D171A;
    line-height: 16px;
  }
}
</style>